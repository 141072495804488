import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  commentData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  comment: string;
  commentsArray: any;
  replyCommentId: any;
  commentHistoryData: any[];
  commentUsers: any[];
  isChatOpen: boolean;
  isOpenAddChat: boolean;
  userId: string;
  message: string;
  userInfo: any
  buttonLoader: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommentController extends BlockComponent<Props, S, SS> {
  apiCommentItemCallId: string = "";
  commentHistoryId: string = ""
  commentApiCallId: string = "";
  createCommentId: string = "";
  likeCommentId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      commentData: [],
      errorMsg: "",
      token: "",
      loading: false,
      comment: "",
      commentsArray: [],
      replyCommentId: null,
      commentHistoryData: [],
      commentUsers: [],
      isChatOpen: false,
      isOpenAddChat: false,
      message: "",
      userId: "",
      userInfo: {},
      buttonLoader: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    const userInfo = JSON.parse(localStorage.getItem("userInfo")!)
    this.setState({userInfo: userInfo})
    this.getCommentData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false,
          });
        } else {
          this.setState({
            commentData: responseJson.data,
            errorMsg: "",
            loading: false,
          });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        console.log("error resp", errorReponse);
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false,
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false,
          });
        }
      }
      if (apiRequestCallId === this.createCommentId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getCommentHistoryData()
            this.setState({isOpenAddChat:false,message: '', buttonLoader: false })
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.likeCommentId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.getCommentData();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

      this.handleGetCommentHistoryData(apiRequestCallId, responseJson)
      this.handleUserData(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCommentData = () => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      "token": String(localStorage.getItem("authToken")),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCommentItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleUserData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiCommentItemCallId) {
      if (!Array.isArray(responseJson)) {
        this.setState({ commentUsers: [] })
      } else {
        this.setState({ commentUsers: responseJson })
      }
    }
  }

  handleGetCommentHistoryData = (apiRequestCallId: any, responseJson: any) => {
    if (this.commentHistoryId === apiRequestCallId) {
      this.setState({ commentHistoryData: responseJson?.data })
    }
  }


  handleChatOpen = (id: any) => {
    this.setState({ userId: id})
    this.setState({ isChatOpen: true }, () => {
      this.getCommentHistoryData()
    })
  }

  handleBack = () => {
    this.setState({ isChatOpen: false })
    this.getCommentData();
  }

  handleAddChat = () => {
    this.setState({ isOpenAddChat: true })
  }

  handleClose = () => {
    this.setState({ isOpenAddChat: false, message: '' })
  }

  handleSend = () => {
    this.setState({ buttonLoader: true, isOpenAddChat: false })
    this.createComment()
  }

  handleChange = (event: any) => {
    this.setState({ message: event.target.value.trimStart() });
  }
  getCommentHistoryData = () => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      "token": String(localStorage.getItem("authToken")),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.commentHistoryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentsHistory}?account_id=${this.state.userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  setComment = (comment: string) => {
    this.setState({ comment });
  };

  createComment = (id?: any, postId?: any) => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const formdata = new FormData()

    formdata.append("[data][attributes][comment]", this.state.message)
    formdata.append("[data][attributes][commentable_id]", this.state.userId)
    formdata.append("[data][attributes][commentable_type]", 'AccountBlock::Account')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  likeChildComments = (commentId: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const data = {
      attributes: {
        comment_id: commentId,
      },
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeCommentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCommentText(comment: any) {
    try {
      return JSON.parse(comment.replace("=>", ":"))?.text;
    } catch {
      return comment;
    }
  }
  // Customizable Area End
}
