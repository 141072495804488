import React from "react";
import { Button , Backdrop, CircularProgress } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import ButtonController, {
  Props,
} from "../ButtonsController.web";
import { t } from "i18next";

export default class Buttons extends ButtonController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const path = this.props.openOtherComponent === "CreateNewGroup" ? "AccountGroups" : "CommunityForumWeb"
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: "20px" }}>
          <Button type="submit" className="grp_saveBtn" onClick={(event) => this.handleSubmit(event, this.props.data)}>{t("SAVE")}</Button>
        </div>
        <Snackbar
          style={{ zIndex: 9999 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.showSnackbars}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severitys}
          >
            {this.state.messages}
          </MuiAlert>
        </Snackbar>
        <Backdrop className="backdrop-loader" open={this.state.isLoading} >
                <CircularProgress color="inherit" />
              </Backdrop>
      </>
    )
  }
}