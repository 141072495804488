// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { configJSON } from "../LandingPageController";

export interface landingPageDataProps {
  id: number
  title: string
  content: string
  image_url: string
  cards: Card[]
}

export interface Card {
  id: number
  title: string
  description: string
  image_url: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isMenuOpen: boolean
  landingPageData: landingPageDataProps
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetLandingPageCallId: string = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
   
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        isMenuOpen: false,
        landingPageData:{
            id: 0,
            title: "",
            content: "",
            image_url: "",
            cards: []
          
        }
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.homePageContentHandler(apiRequestCallId, responseJson)
  }
    // Customizable Area End
  }
  
  async componentDidMount(){
    this.getHomePageContent()
  }

  getHomePageContent= () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetLandingPageCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/landing_pages`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  homePageContentHandler = (apiRequestCallId: any, responseJson: any) => {
    if(apiRequestCallId === this.apiGetLandingPageCallId){
      if (responseJson != null) {
        if (responseJson.data) {
          this.setState({landingPageData: responseJson.data});
        } else {
          return
        }
      }
    }
  }

  handleOpenDrawer = () => {
    this.setState({isMenuOpen: true})
  }

  handleCloseDrawer = () => {
    this.setState({isMenuOpen: false})
  }

  handleLearnMore = () => {

  }

  handlePocketGuide = () => {

  }

  handleJoinNetwork = () => {
    localStorage.setItem("formType", "signin");
    this.props.navigation.navigate("EmailAccountRegistration");
  }


 // Customizable Area End
}
