import React from "react";
import {Chip} from "@material-ui/core";
import { Add, Done } from '@material-ui/icons';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import HandleAddMoreFields from "./handleAddMoreFields.web"
import { t } from "i18next";

export const InterestCard = ({interests, interestIds, handleSelectInterest, showOtherImpactFields, showOtherSkillsFields, handleShowFields,
    otherInterestImpactFields, handleFormFields, handleOtherFields, impactInterestUniqueError, otherInterestSkillsFields, skillsInterestUniqueError,
    userInterestError
}:any) => {
    const other1 = t("Other")
    const other2 = t("Other")
    return(
        <div style={{ flex: '1' }}>
        <div className="form-labels-interest">
        <div className="form-labels">{t("Interested in")}</div>
        </div>
            {
              
               <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
  
                          {
                              Array.isArray(interests.impact_area) && interests.impact_area.map((data: any, indx: number) => (
                                  <Chip
                                     data-test-id={`chip-${indx}`}
                                      key={indx}
                                      className={interestIds && interestIds.includes(data.id) ? "chip-label-selected":"chip-label"}
                                      icon={interestIds && interestIds.includes(data.id) ? <></>:<Add className="icon-style" />}
                                      deleteIcon={interestIds && interestIds.includes(data.id) ? <ClearSharpIcon className="grp_cross-icon-style"/>:<></>}
                                      label={data.attributes.name.charAt(0).toUpperCase() + data.attributes.name.slice(1)}
                                      onClick={() => handleSelectInterest(data.id)}
                                      onDelete={()=>handleSelectInterest(data.id)}
                                      style={{ margin: '5px' }}
                                  />
                              ))
                          }
      
                          {
                              (showOtherImpactFields === true) ?
                              <Chip
                              className={"chip-label-selected"}
                              icon={<Add className="icon-style-selected"/> }
                              onClick={() => handleShowFields("impact_area")}
                              label={other1}
                              data-test-id={"chipClick1_"}
                              />:
                              <Chip
                              className={"chip-label"}
                              icon={<Add className="icon-style"/>}
                              onClick={() => handleShowFields("impact_area")}
                              label={other2}
                              data-test-id={"chipClick2_"}
                              />
                          }
                          
                           {
                              showOtherImpactFields === true &&
                              (
                                  <>
                                  <HandleAddMoreFields handleFormFields={handleFormFields} list={otherInterestImpactFields}
                                   handleOtherFields={handleOtherFields} category={"impact_area"} />
                                  {impactInterestUniqueError && <p className="error-msg">{impactInterestUniqueError}</p>}
                                  </>
                              )
                          }
                     </div>
               </>
            }
               {userInterestError &&
                  <p className="error-msg">{userInterestError}</p>
               }
        </div> 
    )
}

