import React from "react";
import {
    Grid,
    Chip,
    Button,
    Paper,
    Typography
} from "@material-ui/core";
import { logoImg } from "../assets";
import { Add, Done } from '@material-ui/icons';
import HandleAddMoreView from "./AddmoreFields";
import {t} from "i18next";

const InterestForm = ({ state, handleOtherFields, handleContinue,handleAddFormFields, handleShowFields,handleSelectInterest,handleRemoveAddMoreFields, handleRegLogoRedirection }: any) => {

    return (
        <>
        <form onSubmit={handleContinue} className="interest-form">
            <Grid container spacing={3} className="interest-box-in">
                <Grid item xs={12}>
                    <div  onClick={handleRegLogoRedirection} className="logo-item-signup">
                        <img src={logoImg} alt="" className="logo-image-signup" />
                        <Typography className="logo-content-01">
                            {t('Connecting Collaborative Changemakers')}
                        </Typography>
                    </div>
                    <div onClick={handleRegLogoRedirection} className="logo-item-signup-responsive">
                        <img src={logoImg} alt="" className="logo-image-signup" />
                        <Typography className="logo-content-01">
                            {t('Connecting Collaborative Changemakers')}
                        </Typography>
                    </div>
                    <div className="interest-heading-box">
                        <Typography className="interest-title">{t('Select your interest')}</Typography>
                        <Typography className="interest-sub-title">{t('You are joining a network of global collaborative changemakers supporting each other to strengthen collaborative practice to achieve more transformational results, please select the areas you are most interested in.')}</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} lg={6} sm={12} md={6} >
                            <Paper className="interest-block">
                                <Typography className="interest-category">{t('Impact Area')}</Typography>
                                {
                                    state.interestList.map((data: any, indx: number) => (
                                        <>
                                        <Chip
                                            data-test-id="imapact_area_chip"
                                            key={indx}
                                            className={state.interestIds.includes(data.id)? "chip-label-selected": "chip-label"}
                                            icon={state.interestIds.includes(data.id) ? <Done className="icon-style-selected"/>:<Add className="icon-style" />}
                                            label={data.attributes.name.charAt(0).toUpperCase() + data.attributes.name.slice(1)}
                                            onClick={() => handleSelectInterest(data.id, data.attributes.interest_category)}
                                        />
                                    </>))
                                }
                                {state.showOtherImpactFields === true ?
                                    <Chip
                                    className={"chip-label-selected"}
                                    icon={<Done className="icon-style-selected"/> }
                                    onClick={() => handleShowFields("impact_area")}
                                    label={t('Other')}
                                    data-test-id={"chipClick1_impact"}
                                    />:
                                    <Chip
                                    className={"chip-label"}
                                    icon={<Add className="icon-style"/>}
                                    onClick={() => handleShowFields("impact_area")}
                                    label={t('Other')}
                                    data-test-id={"chipClick2_impact"}
                                />
                                }
                                
                                {state.showOtherImpactFields === true &&
                                    (
                                        <>
                                        <HandleAddMoreView handleRemoveAddMoreFields={handleRemoveAddMoreFields} list={state.otherInterestImpactFields} handleOtherFields={handleOtherFields} handleAddFormFields={handleAddFormFields} category={"impact_area"} />
                                        {state.impactInterestUniqueError && <p className="error-msg">{state.impactInterestUniqueError}</p>}
                                        </>
                                    )
                                }
                                
                            </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6} sm={12} md={6} >
                                <Paper className="interest-block">
                                    <Typography className="interest-category">{t('Skills and Approaches')}</Typography>
                                    {
                                        state.skillsList.map((data: any, indx: number) => (
                                            <Chip
                                                data-test-id="skillsList"
                                                key={indx}
                                                className={state.interestIds.includes(data.id)? "chip-label-selected": "chip-label"}
                                                icon={state.interestIds.includes(data.id) ? <Done className="icon-style-selected"/>:<Add className="icon-style" />}
                                                label={data.attributes.name.charAt(0).toUpperCase() + data.attributes.name.slice(1)}
                                                onClick={() => handleSelectInterest(data.id, data.attributes.interest_category)}
                                            />
                                        ))
                                    }
                                    {state.showOtherSkillsFields === true ?
                                        <Chip
                                        className={"chip-label-selected"}
                                        icon={<Done className="icon-style-selected"/> }
                                        onClick={() => handleShowFields("skills_and_approaches")}
                                        label={t('Other')}
                                        data-test-id={"chipClick1_"}
                                        />:
                                        <Chip
                                        className={"chip-label"}
                                        icon={<Add className="icon-style"/>}
                                        onClick={() => handleShowFields("skills_and_approaches")}
                                        label={t('Other')}
                                        data-test-id={"chipClick2_"}
                                    />
                                    }
                                    {state.showOtherSkillsFields === true &&
                                        (
                                            <>
                                            <HandleAddMoreView handleRemoveAddMoreFields={handleRemoveAddMoreFields} list={state.otherInterestSkillsFields} handleOtherFields={handleOtherFields} handleAddFormFields={handleAddFormFields} category={"skills_and_approaches"} />
                                            {state.skillsInterestUniqueError && <p className="error-msg">{state.skillsInterestUniqueError}</p>}
                                            </>
                                        )
                                    } 
                                </Paper>
                    </Grid>
                <Grid item xs={12} className="btn-div">
                    {
                        ( Array.isArray(state.interestIds) && state.interestIds.length !== 0 ||
                        (Array.isArray(state.otherInterestImpactFields) && state.otherInterestImpactFields.length !== 0 && state.otherInterestImpactFields[0]?.name !== "") ||
                        (Array.isArray(state.otherInterestSkillsFields) && state.otherInterestSkillsFields.length !== 0 && state.otherInterestSkillsFields[0]?.name !== "")
                    ) ?
                        <Button type="submit" className="continue-btn" variant="contained" color="primary">{t('Create Account')}</Button>:
                        <Button type="button" className="cont-disable-btn" variant="contained" color="primary">{t('Create Account')}</Button>
                    }
                </Grid>
            </Grid>
        </form>
        </>
    );
};

export default InterestForm;
