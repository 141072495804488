import React from 'react';
import {
    Button,
    Modal,
    Typography,
    Grid,
    Box,
} from '@material-ui/core';
import { t } from 'i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 360,
    borderRadius: '8px',
    outline: 'none',
};

interface GroupActionModalTypes {
    open: boolean
    modalCloseFn: any
    sendClick: any
    icon: any
    warningText: string
}

const GroupActionModal = (props: GroupActionModalTypes) => {
    return (
        <Modal open={props.open}
            onClose={props.modalCloseFn}
            data-test-id='modal-grp-click'>
            <Box sx={style} className='true_false_action_box'>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Box className='icon_text_cont'>
                        <Box style={{
                            justifyContent: 'center',
                            display: 'grid',
                            marginBottom: '16px'
                        }}>
                            {props.icon}
                        </Box>

                        <Typography className='grp_warning_text'>
                            {props.warningText}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Grid container className="true_false_action_cont">
                        <Grid item style={{ width: '48%' }}>
                            <Button
                                type="button"
                                style={{
                                    width: '100%',
                                    fontFamily: "Nunito-Regular",
                                    fontSize: '16px',
                                    color: '#5497FF',
                                    fontWeight: 800,
                                    lineHeight: '24px',
                                    boxShadow: 'none'
                                }}
                                onClick={props.modalCloseFn}
                                className="false_action_click"
                                variant="outlined"
                                color="primary">{t('NO')}
                            </Button>
                        </Grid>
                        <Grid item style={{ width: '48%' }}>
                            <Button
                                type="button"
                                style={{
                                    width: '100%',
                                    fontFamily: "Nunito-Regular",
                                    fontSize: '16px',
                                    color: '#FFFFFF',
                                    fontWeight: 800,
                                    lineHeight: '24px',
                                    boxShadow: 'none'
                                }}
                                onClick={props.sendClick}
                                className="true_action_click"
                                variant="contained"
                                color="primary">{t('YES')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
    );
}

export default GroupActionModal