import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {t} from "i18next";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { isUserloggedIn } from "../../../components/src/NativeWebRouteWrapper/Utils";
export interface OtherInterestFormField {
  name: string,
  interest_category: string,
  created_by_admin: boolean
}
const otherInterestAddMoreField: OtherInterestFormField = {
  name: "", interest_category: "", created_by_admin: false
};
interface SignInInitialValues {
  email: string;
  password: string;
}

interface SignupInitialValues {
  full_name: string;
  email: string;
  password: string;
  cpassword: string;
  preferred_language: number | string;
  country: string;
  selected_interest: any[];

}
const SignupUpFormField: SignupInitialValues = { full_name: "", email: "", password: "", cpassword: "", preferred_language: '', country: "", selected_interest: [] };
const SignInFormField: SignInInitialValues = { email: "", password: "" };

type Color = "success" | "info" | "warning" | "error" | undefined;

interface ValidateFields {
  key: string, label: string, regexp: RegExp | null, errorKey: string
}
const validateFormField = (field: ValidateFields, data: SignupInitialValues | SignInInitialValues) => {

  const name = field.key as keyof typeof data;
  if (data[name] === "") {
    return t(`${field.label}`)+ t(`is required`);
  }
  if (field.regexp && (!field.regexp.test(data[name] as string))) {
    if (field.label === "Name") {
      return t(`Enter Name with alphabets only`);
    } else {
      return t(`${field.label}`)+t(`is invalid`);
    }
  }
  return true;
}

interface IObjectKeys {
  [key: string]: [];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  formType: string;
  anchorEl: HTMLButtonElement | null;
  showPassword: boolean;
  showConfirmPassword: boolean;
  interestList: any;
  otherInterestImpactFields: OtherInterestFormField[];
  otherInterestSkillsFields: OtherInterestFormField[];
  showOtherImpactFields: boolean;
  showOtherSkillsFields: boolean;
  termsOfUse: boolean;
  SignupUpFormField: SignupInitialValues;
  nameError: string;
  emailError: string;
  passwordError: string;
  cPasswordError: string;
  countryError: string;
  languageError: string;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  allValidate: boolean;
  interestIds: number[];
  countryList: any[];
  userInterestError: string;
  rememberMe: boolean;
  logiPasswordError: string;
  loginEmailError: string;
  SignInFormField: SignInInitialValues;
  showLoginPassword: boolean;
  impactInterestUniqueError: string
  skillsInterestUniqueError: string
  openCountry: boolean,
  openLanguage: boolean
  skillsList:any,
  type: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  apiGetInterest: any;
  apiGetCountry: any;
  apiUserSignup: any;
  apiUserSignin: any;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  apiGetSkills: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      formType: "signin",
      anchorEl: null,
      showPassword: false,
      showConfirmPassword: false,
      interestList: {},
      otherInterestImpactFields: [{ ...otherInterestAddMoreField }],
      otherInterestSkillsFields: [{ ...otherInterestAddMoreField }],
      showOtherSkillsFields: false,
      showOtherImpactFields: false,
      SignupUpFormField: SignupUpFormField,
      termsOfUse: false,
      nameError: "",
      emailError: "",
      passwordError: "",
      cPasswordError: "",
      countryError: "",
      languageError: "",
      showSnackbar: false,
      severity: undefined,
      message: "",
      allValidate: false,
      interestIds: [],
      countryList: [],
      userInterestError: "",
      rememberMe: false,
      logiPasswordError: "",
      loginEmailError: "",
      SignInFormField: SignInFormField,
      showLoginPassword: false,
      impactInterestUniqueError: "",
      skillsInterestUniqueError: "",
      openCountry: false,
      openLanguage: false,
      skillsList: [],
      type: ''
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;


    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetCountry:
          this.setState({ countryList: responseJson?.data });
          break;
        case this.apiGetInterest:
          this.setState({ interestList: responseJson?.data });
          break;
        case this.apiGetSkills:
          this.setState({ skillsList: responseJson?.data}) 
          break; 
        case this.apiUserSignup:
          if (responseJson.data) {
            this.setState({
              showSnackbar: true,
              severity: "success",
              message: "User register successfully",
              allValidate: false,
              SignupUpFormField: SignupUpFormField,
              otherInterestImpactFields: [{ ...otherInterestAddMoreField }],
              otherInterestSkillsFields: [{ ...otherInterestAddMoreField }],
              showOtherSkillsFields: false,
              showOtherImpactFields: false,
              termsOfUse: false,
            });
            localStorage.setItem("formType", "signin");
          } else {
            this.setState({
              showSnackbar: true,
              message: responseJson?.errors?.message || 'something went wrong',
              severity: "error",
              allValidate: !(responseJson.errors.message && responseJson.errors.message === "Email is already registered")
            });
            localStorage.setItem("formType", "signup");
          }
          break;
        case this.apiUserSignin:
          if (responseJson && responseJson.meta) {
            localStorage.setItem("authToken", responseJson?.meta.token);
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                id: responseJson?.data.data.id,
                ...responseJson?.data.data.attributes,
              })
            );
            this.setRememeberMeData();
          } else {
            this.setState({
              showSnackbar: true,
              message: responseJson?.errors?.message || 'something went wrong',
              severity: "error",
            });
            localStorage.setItem("formType", "signin");
          }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    isUserloggedIn() && this.props.navigation.navigate("CommunityForumWeb");
    this.getImpactAreaList();
    this.getSkillsApprachesList()
    this.getCountryList();
    this.getRememeberMe();
  }

  setRememeberMeData = () => {
      this.state.rememberMe ?
        localStorage.setItem(
          "remmemberInfo",
          JSON.stringify({
            username: this.state.SignInFormField.email,
            password:this.state.SignInFormField.password,
            remmemberMe:this.state.rememberMe
          })
        ):localStorage.removeItem("remmemberInfo")
        this.props.navigation.navigate("CommunityForum");
  }

  getRememeberMe = () => {
    const remmemberData = JSON.parse(localStorage.getItem("remmemberInfo")!)
    if (remmemberData.username && remmemberData.password) {
      this.setState({ SignInFormField: { ...this.state.SignInFormField, ['email']: remmemberData.username , ['password']: remmemberData.password  }, rememberMe: true});
    }
  }

  handleChange = (e: any) => {
    let value = e.target.value;

    if (e.target.name === "country" || e.target.name === "preferred_language") {
      this.setState({ SignupUpFormField: { ...this.state.SignupUpFormField, [e.target.name]: value } });
    } else if (e.target.name === "termsOfUse") {
      this.setState({ termsOfUse: e.target.checked })
    } else {
      this.setState({ SignupUpFormField: { ...this.state.SignupUpFormField, [e.target.name]: value.trimStart() } });
    }
  };

  handleLoginChange = (e: any) => {
    let value = e.target.value;

    if (e.target.name === "rememberMe") {
      this.setState({ rememberMe: e.target.checked })
    } else {
      this.setState({ SignInFormField: { ...this.state.SignInFormField, [e.target.name]: value.trimStart() } });
    }
  }

  handleLogin = (e: any) => {
    e.preventDefault();
    if (this.handleLoginValidation(this.state.SignInFormField)) {
      const result = {
        data: {
          type: "email_account",
          attributes: this.state.SignInFormField
        }
      }
      this.userLogin(result);
    }
  };

  handleLoginValidation = (formState: SignInInitialValues) => {

    const validateFields = [
      { key: 'email', label: "Email", regexp: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, errorKey: 'loginEmailError' },
      { key: 'password', label: "Password", regexp: null, errorKey: 'loginPasswordError' },
    ];
    let isValid = true;
    let loginErrorFields = { ...this.state };
    validateFields.forEach((itm: ValidateFields) => {
      const res = validateFormField(itm, formState);
      loginErrorFields = { ...loginErrorFields, [itm.errorKey]: (res === true) ? "" : res };
      if (res !== true) {
        isValid = false;
      }
    });
    this.setState({ ...loginErrorFields });
    return isValid;
  };

  handleValidation = (formState: SignupInitialValues) => {

    const validateFields = [
      { key: 'full_name', label: "Name", regexp: /^[a-zA-Z ]{2,40}$/, errorKey: 'nameError' },
      { key: 'email', label: "Email", regexp: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, errorKey: 'emailError' },
      { key: 'password', label: "Password", regexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,}$/, errorKey: 'passwordError' },
      { key: 'cpassword', label: "Confirm Password", regexp: null, errorKey: 'cPasswordError' },
      { key: 'country', label: "Country", regexp: null, errorKey: 'countryError' },
      { key: 'preferred_language', label: "Language", regexp: null, errorKey: 'languageError' }];
    let isValid = true;
    let errorFields = { ...this.state };
    validateFields.forEach((itm: ValidateFields) => {
      const res = validateFormField(itm, formState);

      errorFields = { ...errorFields, [itm.errorKey]: (res === true) ? "" : res };
      if (res !== true) {
        isValid = false;
      }
    });
    if (formState.password !== formState.cpassword) {
      errorFields = { ...errorFields, cPasswordError: t('Confirm Password does not match') };
      isValid = false;
    }
    this.setState({ ...errorFields });
    return isValid;
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  handleSelectInterest = (id: number, type: string) => {
    this.setState({type: type})
    if (this.state.interestIds.includes(id)) {
      let idArray = [...this.state.interestIds];
      let selectedIdArray = [...this.state.SignupUpFormField.selected_interest];
      idArray = idArray.filter(item => item !== id);
      const indxKey = selectedIdArray.findIndex(item => item.interest_id === id)
      selectedIdArray.splice(indxKey, 1)
      this.setState({ SignupUpFormField: { ...this.state.SignupUpFormField, selected_interest: selectedIdArray }, interestIds: idArray });
    } else {
      const interest_arr = this.state.SignupUpFormField.selected_interest;
      interest_arr.push({ 'interest_id': id });
      const interest_id_arr = this.state.interestIds;
      interest_id_arr.push(id);
      this.setState({ SignupUpFormField: { ...this.state.SignupUpFormField, selected_interest: interest_arr }, interestIds: interest_id_arr });
    }
  }

  checkImpactUniqueInterest = () => {

    let valueArr = this.state.otherInterestImpactFields.map(function (item) { return item.name });
    const isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });
    if (isDuplicate) {
      return true;
    }
    let uniqueImpact = false;
    this.state.otherInterestImpactFields.forEach((o1: any) => {
      const checkImpact = this.state.interestList.filter((o2: any) => (o1.name === o2.attributes.name));
      if (checkImpact.length) {
        uniqueImpact = true;
      }
    });
    if (uniqueImpact) {
      return true
    }
    return false;
  }

  checkSkillsUniqueInterest = () => {

    let valueArr = this.state.otherInterestSkillsFields.map(function (item) { return item.name });
    const isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });
    if (isDuplicate) {
      return true;
    }
    let uniqueSkills = false;
    this.state.otherInterestSkillsFields.forEach((o1: any) => {
      const checkSkills = this.state.skillsList.filter((o2: any) => (o1.name === o2.attributes.name));
      if (checkSkills.length) {
        uniqueSkills = true;
      }
    })
    if (uniqueSkills) {
      return true
    }
    return false;
  }

  handleOtherFields = (e: any, category: string, index: number) => {

    if (category === 'impact_area') {
      let otherFields = [...this.state.otherInterestImpactFields];
      otherFields[index] = { ...otherFields[index], name: e.target.value, interest_category: category };
      this.setState({ otherInterestImpactFields: otherFields });
    } else {
      let otherSkillFields = [...this.state.otherInterestSkillsFields];
      otherSkillFields[index] = { ...otherSkillFields[index], name: e.target.value, interest_category: category };
      this.setState({ otherInterestSkillsFields: otherSkillFields });
    }
  }

  handleNext = (e: any) => {
    e.preventDefault();
    if (this.handleValidation(this.state.SignupUpFormField)) {
      if (this.state.termsOfUse === false) {
        this.setState({
          showSnackbar: true,
          message: t('You have to agree with Privacy Policy and Terms and Conditions to Sign up'),
          severity: "error",
          allValidate: false
        });
      } else {
        this.setState({
          showSnackbar: false,
          message: "",
          severity: undefined,
          allValidate: true
        });
      }

    }
  };

  handleContinue = (e: any) => {
    e.preventDefault();
    let checkRes = this.checkImpactUniqueInterest();
    let checkRes2 = this.checkSkillsUniqueInterest();
    if (!checkRes && !checkRes2) {
      this.setState({skillsInterestUniqueError: "", impactInterestUniqueError: "" });
      let user_interests_attributes = [...this.state.SignupUpFormField.selected_interest];
        if (this.state.otherInterestImpactFields.length && this.state.showOtherImpactFields === true) {
          const otherImpact = this.state.otherInterestImpactFields.map((item: any) => {
            return { interest_attributes: { ...item } }
          })
          user_interests_attributes = [...user_interests_attributes, ...otherImpact];
        }
        if (this.state.otherInterestSkillsFields.length && this.state.showOtherSkillsFields === true) {
          const otherSkills = this.state.otherInterestSkillsFields.map((item: any) => {
            return { interest_attributes: { ...item } }
          })
          user_interests_attributes = [...user_interests_attributes, ...otherSkills];

        }
        let finalData = { ...this.state.SignupUpFormField, user_interests_attributes } as any;
        delete finalData.selected_interest;
        const result = {
          data: {
            type: "email_account",
            attributes: finalData
          }
        }
        if (finalData.user_interests_attributes.length == 0) {
          this.setState({ userInterestError: "Interest should be mandatory" });
        } else {
          this.userSignup(result);
        }
    } else {

        checkRes && this.setState({ skillsInterestUniqueError: "Interest name should not be duplicate" });
        checkRes2 && this.setState({ impactInterestUniqueError: "Interest name should not be duplicate" });
      }
  }

  goToForgotPage = () => {
    this.props.navigation.navigate("ForgotPassword");
  }

  handleRegLogoRedirection = () => {
    window.location.href = "/";
  }

  handleAddFormFields = (type: string) => {
    if (type === "impact_area") {
      const otherInterestImpactFieldData = this.state.otherInterestImpactFields;
      otherInterestImpactFieldData.push({ ...otherInterestAddMoreField });
      this.setState({ otherInterestImpactFields: otherInterestImpactFieldData })
    } else {
      const otherInterestSkillsFieldData = this.state.otherInterestSkillsFields;
      otherInterestSkillsFieldData.push({ ...otherInterestAddMoreField });
      this.setState({ otherInterestSkillsFields: otherInterestSkillsFieldData })
    }
  }

  handleRemoveAddMoreFields =(index: number,category: string) => {
    
    if (category === "impact_area") {
      const otherInterestImpactFieldData = this.state.otherInterestImpactFields;
      otherInterestImpactFieldData.splice(index,1);
      this.setState({ otherInterestImpactFields: otherInterestImpactFieldData })
    } else {
      const otherInterestSkillsFieldData = this.state.otherInterestSkillsFields;
      otherInterestSkillsFieldData.splice(index,1);
      this.setState({ otherInterestSkillsFields: otherInterestSkillsFieldData })
    }
  }

  handleShowFields = (type: string) => {
    if (type === "impact_area") {
      if (this.state.showOtherImpactFields === true) {
        this.setState({ showOtherImpactFields: false, otherInterestImpactFields: [] })
      } else {
        this.setState({ showOtherImpactFields: true, otherInterestImpactFields: [{ ...otherInterestAddMoreField }] })
      }
    } else {
      if (this.state.showOtherSkillsFields === true) {
        this.setState({ showOtherSkillsFields: false, otherInterestSkillsFields: [] })
      } else {
        this.setState({ showOtherSkillsFields: true, otherInterestSkillsFields: [{ ...otherInterestAddMoreField }] })
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  handleFormChange = (event: any, newValue: string) => {
    this.setState({ formType: newValue });
    localStorage.setItem("formType", newValue);

  };

  handleInfoClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleInfoClose = () => {
    this.setState({ anchorEl: null });
  };

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }
  handleLoginShowPassword = () => {
    this.setState({ showLoginPassword: !this.state.showLoginPassword });
  };

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };
  txtInputLastNamePrpos = {};
  txtPhoneNumberProps = {};

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  getImpactAreaList = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetInterest = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_interests/impact_areas`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSkillsApprachesList = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetSkills = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_interests/skills_and_approaches`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCountryList = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCountry = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  userSignup = (data: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUserSignup = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userSignupApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  userLogin = (data: any) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUserSignin = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userSigninApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleOpenCountry = () =>{
    document.body.classList.add('my-class')  
    this.setState({openCountry: true})
  }
  handleCloseCountry = () =>{
      document.body.classList.remove('my-class')  
      this.setState({openCountry: false})
  }
  handleOpenLanguage = () =>{
      document.body.classList.add('my-class')  
      this.setState({openLanguage: true})
   }
  handleCloseLanguage = () =>{
     document.body.classList.remove('my-class')  
     this.setState({openLanguage: false})
   }
  // Customizable Area End
}
