import React from "react";
import {
    Grid,
    FormControl,
    FormLabel,
    Button,
    Box,Typography,
    InputAdornment,
    Input,
} from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import {t} from "i18next";

const webStyle = {
    errorMsg : {
        color: 'red',
        marginBottom: '0px',
        marginTop: '0px',
        textAlign: 'left' as const,
        fontSize:'12px',
        fontFamily: "Nunito-Regular, sans-serif"
    }
}

const NewPasswordForm = ({state,disableCreatePassword, handlePasswordChange, handleShowNewPassword, handleShowConfirmPassword, handlePasswordSubmit }: any) => {
    return (
        <>
            <div className='forgot-password-text'>
                <Box>
                    <Typography className='forgot-text-one'>{t('Set Password')}</Typography>
                </Box>
                <Box>
                    <Typography className='forgot-text-two'>{t('Set a new password to login to your profile')}</Typography>
                </Box>
            </div>
            <form onSubmit={handlePasswordSubmit} className="new-password-form">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('New Password')}
                        </FormLabel>
                        <Input
                            disableUnderline
                            value={state.newPassword}
                            data-test-id="shownewpassInput"
                            type={state.showNewPassword ? 'text' : 'password'}
                            placeholder={`xxxxxxxxxx`}
                            className="text-box"
                            name={'newPassword'}
                            onChange={handlePasswordChange}
                            endAdornment={
                                <InputAdornment className="btn-show-pass" onClick={handleShowNewPassword} position="start">
                                    {state.showNewPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {state.passError &&
                        <Typography style={webStyle.errorMsg}>{t(state.passError) }</Typography>
                    }
                    </Grid>
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel className="form-labels">
                            {t('Confirm Password')}
                        </FormLabel>
                        <Input
                            disableUnderline
                            value={state.cnfmPassword}
                            type={state.showConfirmPassword ? 'text' : 'password'}
                            placeholder={`xxxxxxxxxx`}
                            className="text-box"
                            name={'cnfmPassword'}
                            onChange={handlePasswordChange}
                            endAdornment={
                                <InputAdornment className="btn-show-pass" data-test-id="showpass-input" onClick={handleShowConfirmPassword} position="start">
                                    {state.showConfirmPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {state.cnfrmPassError &&
                        <Typography style={webStyle.errorMsg}>{t(state.cnfrmPassError) }</Typography>
                    }
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" disabled={disableCreatePassword} className="forgot-submit-btn" variant="contained" color="primary">{t('CREATE NEW PASSWORD')}</Button>                    
                    </Grid>
                </Grid>
            </form>
        </>

    );
};

export default NewPasswordForm;
