import React from "react";
import PhoneInput from "react-phone-input-2";
import {
    Grid,
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import 'react-phone-input-2/lib/style.css';
import '../../assets/customStyles.scss';
import { showError } from "./ShowError.web";
import { t } from "i18next";
import { styled } from '@material-ui/styles';

interface MobileInputRendererProps {
    state: any
    handlePhoneNumberChange: any
    profileErrorField: any
    onChange: any
}

export const CustomLabel = styled('span')(() => ({
    color: '#0F172A',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '24px',
    fontFamily: "Nunito-Regular",
    margin: '0px 0px 0px 4px'
}));
export const CustomCheckbox = styled(Checkbox)(() => ({
    '&.Mui-checked': {
        color: '#5497FF',
    },
    '&.MuiCheckbox-root': {
        padding: '0px',
        margin: '0px'
    },
    '&.MuiIconButton-root': {
        width: '30px',
        height: '40px'
    }
}));
const MobileNumberInput = (props: MobileInputRendererProps) => {

    return (
        <Grid item xs={12} lg={6} sm={12} md={6}>
        <FormControl fullWidth>
            <FormLabel className="heading_title">
                {t('Mobile Number')}
            </FormLabel>
            <PhoneInput
                value={props.state.ProfileFormField.full_phone_number}
                onChange={props.handlePhoneNumberChange}
                country={'in'}
                inputStyle={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #CBD5E1',
                    borderRadius: '8px',
                    paddingLeft: '45px',
                    height: 'inherit',
                    fontFamily: "Nunito-Regular",
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: '#1E293B'
                }}
                inputProps={{
                    required: true,
                    id: "PhoneNumber",
                }}
                buttonStyle={{
                    borderRadius: '8px 0px 0px 8px',
                }}
                containerStyle={{
                    height: '56px'
                }}
                placeholder="Enter mobile number"
                enableLongNumbers
            />
            {showError({
                errorKey: 'phoneError',
                profileErrorField: props.profileErrorField
            })}
            <FormControlLabel data-test-id='custom-check'
                    control={
                        <CustomCheckbox
                            checked={(props.state.ProfileFormField.number_visible !== undefined && props.state.ProfileFormField.number_visible)}
                            onChange={props.onChange}
                            name="number_visible"
                            icon={
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#64748B" />
                                </svg>
                            }
                            checkedIcon={
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="20" height="20" rx="6" fill="#5497FF" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z" fill="white" />
                                </svg>
                            }
                        />
                    }
                    label={<CustomLabel>{t('Want your number to be visible to other users')}</CustomLabel>}
                    style={{ marginLeft: '0px', marginRight: '0px' }}
                    className="phone_number_cont"
                />
         </FormControl>
        </Grid>
    );
};
export default MobileNumberInput


