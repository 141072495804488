import React from "react";
// Customizable Area Start
import EditIconOutlined from '@material-ui/icons/EditOutlined';
import { Grid, TableContainer, Paper, Portal } from '@material-ui/core';
import { Rectangle5, user4, camera, gallery, download, tickmark } from "./assets"
import "../assets/userprofilebasic.scss";
import UserProfileBasicController, { Props, SideBarScreenNames } from "./UserProfileBasicController";
import SideBar from "../../../components/src/SideBar.web";
import RightBar from "../../../components/src/RightBar.web";
import WebHeader from "../../../components/src/WebHeader.web";
import UserAboutMe from "./components/UserAboutMe.web";
import UserPost from "./components/UserPost.web";
import UserGroups from "./components/UserGroups.web";
import RecentActivities from "./components/RecentActivities.web";
import UserMedia from "./components/Media.web";
import { ImageSelectInputWithIcon } from "./components/ImageSelectBox";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Header from "../../../components/src/Header.web";
import ConfirmationBox from "../../../components/src/ConfirmationBox.web";
import { t } from "i18next";
import MediaSlider from "../../CommunityForum/src/components/MediaSlider.web";
import Comments from "../../comments/src/Comments.web";
// Customizable Area End

export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    onSideBarNavigateScreen = (screen: string) => {
        this.onSideBarNavigate(screen)
    }
    onCoverModalOpen = () => {
        this.showModal(); this.setCoverModal()
    }

    renderGroups = () => {
        const { userGroups, userData, isKabab, exploreGroups } = this.state
        return(
            <UserGroups
                user={userData}
                isKabab={isKabab}
                myGroups={userGroups}
                userId={this.state.userId}
                isMenu={this.state.isMenu}
                isMenu2={this.state.isMenu2}
                joinedGroups={exploreGroups}
                members={this.state.members}
                isMember={this.state.isMember}
                groupName={this.state.groupName}
                navigation={this.props.navigation}
                currentIndex={this.state.currentIndex}
                groupMembers={this.state.groupMembers}
                membersIndex={this.state.membersIndex}
                exploreGroupPagination={this.state.exploreGroupsPagination}
                handleCreateGroupRedirection={this.handleCreateGroupRedirection}
                getGroupMembersDetail={this.getGroupMembersDetail}
                handleRemoveMemberApi={this.handleRemoveMemberApi}
                onMemberViewHandler={this.onMemberViewHandler}
                onMoreClickHandler={this.onMoreClickHandler}
                onMore2ClickHandler={this.onMore2ClickHandler}
                handleMemberReport={this.handleMemberReport}
                handleMoreMember={this.handleMoreMember}
                handleMakeMember={this.handleMakeMember}
                onLeaveHandler={this.onLeaveHandler}
                onMoreIconHandler={this.onMoreIconHandler}
                handleExploreGroupsPagination={this.handleExploreGroupsPagination}
                pendingRequestList={this.state.pendingRequestList}
                pendingRequestListClick={this.getPendingMemberRequestsList}
                rejectPendingRequestFunction={this.rejectPendingRequest}
                acceptPendingRequestFunction={this.acceptPendingRequest}
                pendingListModalState={this.state.pendingListModalState}
                pendingListModalFunction={this.pendingListModalFunction}
                joinGrpFunction={this.handleJoinGroup}
                leaveGrpModalState={this.state.leaveGrpModalState}
                leaveGrpModalFunction={this.leaveGrpModalFunction}
                leaveGrpFunction={this.handleLeaveGroup}
                deleteGrpModalState={this.state.deleteGrpModalState}
                deleteGrpModalFunction={this.deleteGrpModalFunction}
                deleteGrpFunction={this.handleDeleteGroup}
            />
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            userData,
            userMedia,
            userMyPosts,
            profileSideBar,
            confirmModalOpen,
            userRecentActivities
        } = this.state;
        // Customizable Area End
        return (
            <div>
                <Header type={"UserProfileBasicBlock"} navigation={this.props.navigation} profileImage={userData?.attributes?.photo} 
                fromScreen={this.state.activeTab}/>
                <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }}>
                    <Grid container className="profile_user_new">
                        <Grid container spacing={2}>
                            {!this.state.activeTab &&
                                <Grid item xs={12} md={3} lg={3} xl={3} sm={12}>
                                    <SideBar navigation={this.props.navigation} onSideBarNavigate={this.onSideBarNavigateScreen} id={profileSideBar} />
                                </Grid>
                            }
                            {this.state.activeTab ?
                                <Grid item xs={12} md={12} lg={12} sm={12}>
                                    {this.renderGroups()}
                                </Grid>
                                :
                                <Grid item xs={12} md={9} lg={9} sm={12}>
                                    {this.state.profileSideBar === SideBarScreenNames.aboutMe &&
                                        <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
                                            <Paper elevation={3} className="main_section_about_me" >
                                                <UserAboutMe user={userData.attributes || {}} navigation={this.props.navigation}/>
                                            </Paper>
                                        </TableContainer>
                                    }
                                    {this.state.profileSideBar === SideBarScreenNames.groups &&
                                        this.renderGroups()
                                    }
                                    {this.state.profileSideBar === SideBarScreenNames.postAboutMe && <UserPost state={this.state} posts={userMyPosts} commentInput={this.state.usercommentInput}
                                        onCommentChange={this.onCommentChange} onCommentHandler={this.onUserCommentHandler} onLikeHandler={this.onLikeHandler} commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
                                        isCommentEnable={this.state.isCommentEnable} commentIndex={this.state.commentIndex} replyIndex={this.state.replyIndex} commentActiveIndex={this.state.commentActiveIndex} isReplied={this.state.isReplied} repiedIndex={this.state.repiedIndex}
                                        isCommentActive={this.state.isCommentActive} isReportModal={this.state.isReportModal} reportIndex={this.state.reportIndex}
                                        onRepliedHandler={this.onRepliedHandler} onReplyHandler={this.onReplyHandler} onClickReplyComment={this.onClickReplyComment} onClickCommentIndex={this.onClickCommentIndex}
                                        onReportModalClickHandler={this.onReportModalClickHandler} allDownloadHandler={this.allDownloadHandler} handleConfirmModalOpen={this.handleConfirmModalOpen}
                                        onMediaModalHandler={this.onMediaModalHandler} playVideo={this.playVideo} playAudio={this.playAudio} onShareInputHandler={this.onShareInputHandler} 
                                        onrhDescriptionChange={this.onrhDescriptionChange}
                                        onrhLanguageChange={this.onrhLanguageChange}
                                        handleOnShareToResourceHub={this.handleOnShareToResourceHub}
                                        />}
                                    {this.state.profileSideBar === SideBarScreenNames.media && <UserMedia userMedia={userMedia} handleEndPoint={this.handleEndPoint} />}
                                    {this.state.profileSideBar === SideBarScreenNames.recentActivities && <RecentActivities list={userRecentActivities} commentInput={this.state.usercommentInput}
                                        onCommentChange={this.onCommentChange} onCommentHandler={this.onUserCommentHandler} onLikeHandler={this.onLikeHandler} commentBoxVisibilityHandler={this.commentBoxVisibilityHandler}
                                        isCommentEnable={this.state.isCommentEnable} commentIndex={this.state.commentIndex} replyIndex={this.state.replyIndex} commentActiveIndex={this.state.commentActiveIndex} isReplied={this.state.isReplied} repiedIndex={this.state.repiedIndex}
                                        isCommentActive={this.state.isCommentActive} isReportModal={this.state.isReportModal} reportIndex={this.state.reportIndex}
                                        onRepliedHandler={this.onRepliedHandler} onReplyHandler={this.onReplyHandler} onClickReplyComment={this.onClickReplyComment} onClickCommentIndex={this.onClickCommentIndex}
                                        onReportModalClickHandler={this.onReportModalClickHandler} allDownloadHandler={this.allDownloadHandler} handleConfirmModalOpen={this.handleConfirmModalOpen}
                                        onMediaModalHandler={this.onMediaModalHandler} playVideo={this.playVideo} playAudio={this.playAudio} onShareInputHandler={this.onShareInputHandler} />}

                                    {this.state.profileSideBar === SideBarScreenNames.privateMessage && <Comments navigation={undefined} id="" />}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <ConfirmationBox
                        open={confirmModalOpen}
                        handleClose={this.handleConfirmModalClose}
                        handleSubmit={this.handleConfirmModalSubmit}
                    />
                    <Portal>
                        <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={5000}
                        onClose={this.handleCloseSnackbar}
                        open={this.state.showSnackbar}
                    >
                        <MuiAlert
                            severity={this.state.severity}
                            onClose={this.handleCloseSnackbar}
                        >
                            {this.state.message}
                        </MuiAlert>
                    </Snackbar>
                    </Portal>
                </div>
            </div>

        );
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

}
// Customizable Area Start
// Customizable Area End
