// Customizable Area Start
import React from 'react';
import HomePageController, { Props } from './HomePageController.web';
import { Box, Button, Drawer, Grid, IconButton, List, ListItem, ListItemText, styled, Typography } from '@material-ui/core';
import "../../assets/landing.scss";
import { t } from 'i18next';
import { footerImage2, footerImg1, landingCoverImg, logoImg, mapImg } from '../../src/assets';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

export class HomePage extends HomePageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render(){
        // Customizable Area Start
        // Customizable Area End
        return(
            // Customizable Area Start
            <>
            <StyledMainBox>
                <InnerBox>
                {!this.state.isMenuOpen && 
                <HeaderGrid container style={{marginTop:'20px'}}>
                    <Grid item xs={6} sm={4} md={4} lg={6} style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                        <img src={logoImg} alt="logo"  />
                        <StyledLogo>
                            {t('Connecting Collaborative Changemakers')}
                        </StyledLogo>
                    </Grid>
                    <StyledGrid item xs={12} sm={8} md={8} lg={6}>
                        <Typography data-test-id="learnMoreWeb" style={{...webStyle.learnMoreText, cursor:'pointer'}} onClick={this.handleLearnMore}>learn More</Typography>
                        <Box style={webStyle.btnContainer}>
                            <Button data-test-id="pocketGuideWeb" variant="contained" style={webStyle.whiteBtn} onClick={this.handlePocketGuide}>Pocket Guide</Button>
                            <Button data-test-id="joinNetworkWeb" variant="contained" style={webStyle.blueBtn} onClick={this.handleJoinNetwork} color="primary">Join Our Network</Button>
                        </Box>
                    </StyledGrid>
                    <StyledMobileGrid item xs={6} sm={8} md={8} lg={6}>
                    <Box className="hamburger-icon">
                        <Box style={{ width: '100%', padding: '7px 0px' }}>
                            <Box className='nav-items'>
                                <Box className='item' style={{left:'0'}}>
                                    <IconButton
                                        color="inherit"
                                        data-test-id="hamburgerIcon"
                                        edge="start"
                                        onClick={this.handleOpenDrawer}
                                    >
                                        <MenuIcon style={{ fontSize: '30px', color: 'white' }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    </StyledMobileGrid>
                </HeaderGrid>
                }
                 <Drawer
                    data-test-id="close"
                    anchor="top"
                    open={this.state.isMenuOpen}
                    onClose={this.handleCloseDrawer}
                >
                    <List>
                        <ListItem style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                            <Grid item xs={8} sm={8} md={4} lg={6} style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                            <img src={logoImg} alt="logo"  />
                            <Typography style={webStyle.logoText}>
                                {t('Connecting Collaborative Changemakers')}
                            </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={6} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                <CloseIcon onClick={this.handleCloseDrawer} style={{color:'#000', cursor:'pointer'}}/>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <ListItemText data-test-id="learnMoreMob" style={{cursor:'pointer'}} primary={t("learn More")} />
                        </ListItem>
                        <ListItem>
                        <ListItemText data-test-id="pocketGuideMob" style={{cursor:'pointer'}} primary={t("Pocket Guide")} />
                        </ListItem>
                        <ListItem button>
                            <ListItemText data-test-id="joinNetText" style={{cursor:'pointer'}} primary={t("Join Our Network")} onClick={this.handleJoinNetwork}/>
                        </ListItem>
                        </List>
                </Drawer>
                <Grid container>
                    <Grid item xs={12} lg={12} sm={12} md={12}>
                        <StyledTitle>{this.state.landingPageData.title}</StyledTitle>
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12} md={12}>
                        <StyledContent>{this.state.landingPageData.content}</StyledContent>
                    </Grid>
                </Grid>
                <CardContainer container spacing={3}>
                    {
                        this.state.landingPageData.cards.map((data) => {
                            return (
                                <Grid item xs={12} sm={12} md={4} lg={4} style={webStyle.card}>
                                    <img src={data.image_url} style={webStyle.cardImg} />
                                    <Typography style={webStyle.cardTitle}>{data.title}</Typography>
                                    <Typography style={webStyle.cardContent}>{data.description}</Typography>
                                </Grid>
                            )
                        })
                    }
                </CardContainer>
                </InnerBox>
                </StyledMainBox>
                <Box style={{margin:'80px 0 -20px', textAlign:'center'}}>
                    <img src={this.state.landingPageData.image_url} className='footer-img'/>
                </Box>
                <Box style={{position:'relative'}}>
                    <img src={footerImage2} style={{position:'relative', width:'100%', height:'300px'}}/>
                    <FooterContentBox>
                     <Box style={{display:'flex', alignItems:'flex-end', justifyContent:'flex-start'}}>
                        <img src={logoImg} alt="logo"/>
                        <StyledFooterLogo>
                            {t('Connecting Collaborative Changemakers')}
                        </StyledFooterLogo>
                    </Box>
                    <Box style={{display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
                        <Typography style={webStyle.footerText}>PBA 2023. All rights reserved.</Typography>
                    </Box>
                    </FooterContentBox>
                </Box>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyle = {
    logoText: {
        fontFamily: "Comfortaa-Bold, sans-serif",
        fontWeight: 700,
        fontSize: '13.76px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        color: '#475569',
        letterSpacing: '-0.5%',
        paddingLeft: '18px',
        width: '150px'
    },
    learnMoreText : {
        fontFamily: "Nunito-Regular, sans-serif",
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#475569',
        cusor:'pointer'
    },
    whiteBtn : {
        fontFamily: "Nunito-Regular, sans-serif",
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#5497FF',
        borderRadius: '6px',
        padding: '12px 16px',
        backgroundColor: '#fff',
        border: '2px solid #5497FF',
        boxShadow:'none'
    },
    blueBtn : {
        fontFamily: "Nunito-Regular, sans-serif",
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#fff',
        borderRadius: '6px',
        padding: '12px 16px',
        backgroundColor: '#5497FF',
        border: '2px solid #5497FF',
        boxShadow:'none'
    },
    rightHeader: {
        display:'flex',
        alignItems:'center',
        justifyContent:"flex-end",
        gap:'16px'
    },
    btnContainer: {
        display:'flex',
        alignItems:'center',
        gap:'16px'
    },
    title: {
        marginTop: '100px',
        fontFamily: "Nunito-Regular, sans-serif",
        fontWeight: 800,
        fontSize: '44px',
        lineHeight: '60.02px',
        color: '#fff',
        textAlign:'center' as const,
        wordBreak: 'break-word' as const
    },
    content: {
        marginTop: '10px',
        fontFamily: "Nunito-Regular, sans-serif",
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '27.28px',
        color: '#fff',
        textAlign:'center' as const,
        padding: '0 160px',
        wordBreak: 'break-word' as const
    },
    card: {
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:'column' as const,
        backgroundColor:'#fff',
        padding:'16px',
        borderRadius:'16px',
        height: 'auto',
        gap:'10px',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.06)'
    },
    cardTitle: {
        fontFamily: "Nunito-Regular, sans-serif",
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '32.74px',
        color: '#475569',
        textAlign:'center' as const,
        wordBreak: 'break-word' as const
    },
    cardContent: {
        fontFamily: "Nunito-Regular, sans-serif",
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '21.82px',
        color: '#475569',
        textAlign:'center' as const,
        wordBreak: 'break-word' as const
    },
    cardImg: {
        width:'70px',
        height:'70px'
    },
    footerText : {
        fontFamily: "Nunito-Regular, sans-serif",
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: 'rgba(71, 85, 105, 1)',
    }
}

const CardContainer = styled(Grid)({
    padding: '100px 190px 60px',
    gridColumnGap: '24px',
    flexWrap:'nowrap' as const,
    '@media (max-width: 1380px)': {
        padding: '80px 70px 60px',
    },
    '@media (max-width: 1260px)': {
        padding: '80px 50px 60px',
    },
    '@media (max-width: 1024px)': {
        padding: '80px 30px 60px',
    },
    '@media (max-width: 960px)': {
        padding: '80px 150px 60px',
        flexWrap:'wrap' as const,
        gridRowGap: '24px',
    },
    '@media (max-width: 767px)': {
        padding: '80px 80px 60px',
        flexWrap:'wrap' as const,
        gridRowGap: '24px',
    },
    '@media (max-width: 600px)': {
        padding: '80px 0px 60px',
        flexWrap:'wrap' as const,
        gridRowGap: '24px',
    }
})

const InnerBox = styled(Box)({
    padding:'0px 80px',
    '@media (max-width: 767px)': {
        padding:'0px 30px',
    }
})

const StyledTitle = styled(Typography)({
    marginTop: '120px',
    fontFamily: "Nunito-Regular, sans-serif",
    fontWeight: 800,
    fontSize: '44px',
    lineHeight: '60.02px',
    color: '#fff',
    wordBreak: 'break-word',
    textAlign:'center' as const,
    '@media (max-width:767px)': {
        fontSize: '30px',
        lineHeight: '40px',
    },
    '@media (max-width:460px)': {
        fontSize: '28px',
        lineHeight: '40px',
    }
})

const StyledContent = styled(Typography)({
    marginTop: '16px',
    fontFamily: "Nunito-Regular, sans-serif",
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '27.28px',
    color: '#fff',
    wordBreak: 'break-word',
    textAlign:'center' as const,
    padding: '0 160px',
    '@media (max-width:767px)': {
        fontSize: '18px',
        lineHeight: '22px',
        padding: '0 30px',
    },
    '@media (max-width:460px)': {
        fontSize: '16px',
        lineHeight: '20px',
        padding: '0px',
    }
})

const StyledGrid = styled(Grid)({
    display:'flex',
    alignItems:'center',
    justifyContent:"flex-end",
    gap:'16px',
    '@media (max-width:767px)': {
        display: 'none'
    }
})

const StyledMobileGrid = styled(Grid)({
    display:'flex',
    alignItems:'center',
    justifyContent:"flex-end" as const,
    gap:'16px',
    '@media (min-width:768px)': {
        display: 'none'
    }
})

const HeaderGrid = styled(Grid)({
    display:'flex', 
    padding:'20px',
    alignItems:'center', 
    justifyContent:'space-between' as const, 
    margin:'0 auto',
    borderRadius:'16px', 
    backgroundColor:'#fff', 
    boxShadow: '0px 8px 32px 0px #0000000F',
    '@media (max-width:767px)': {
        backgroundColor:'transparent' as const, 
        boxShadow: 'none',
        padding:'0px'
    }
})

const StyledMainBox = styled(Box)({
    backgroundImage: `url(${landingCoverImg}), url(${mapImg})`,
    backgroundSize: 'cover',
    objectFit:'cover', 
    '@media (max-width: 600px)': {
        padding:'24px 20px',
    }
})

const StyledLogo = styled(Typography)({
    fontFamily: "Comfortaa-Bold, sans-serif",
    fontWeight: 700,
    fontSize: '13.76px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    color: '#475569',
    letterSpacing: '-0.5%',
    paddingLeft: '18px',
    width: '150px',
    '@media (max-width: 767px)': {
        color:'#fff',
    }
})

const FooterContentBox = styled(Box)({
    position: 'absolute', 
    top:'200', 
    display:'flex', 
    alignItems:'center', 
    justifyContent:'space-between', 
    left: 0, right: 0, 
    padding: '0 60px',
    '@media (max-width: 600px)': {
        top:'150',
        flexDirection:'column' as const,
        gap: '16px'
    }
})

const StyledFooterLogo = styled(Typography)({
    fontFamily: "Comfortaa-Bold, sans-serif",
    fontWeight: 700,
    fontSize: '13.76px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    color: '#475569',
    letterSpacing: '-0.5%',
    paddingLeft: '18px',
    width: '150px'
})

export default HomePage
// Customizable Area End