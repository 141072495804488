import React from "react";
import { Box, Avatar, Typography, Button, Modal, Paper, TextField, TableContainer, Grid, Divider } from "@material-ui/core";
import CommentsController, { Props } from "./CommentsController";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from "moment";
import '../assets/Comments.scss'
import { t } from "i18next";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 588,
    borderRadius: '8px',
    outline: 'none',
};


class Comments extends CommentsController {
    constructor(props: Props) {
        super(props);
    }

    renderChatUi = () => {
        if (!this.state.isChatOpen) {
            return (
                <Box style={{ paddingTop: '0px', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 600, lineHeight: '22px', color: '#475569', fontFamily: "Nunito-Regular" }}>
                        Private Message
                    </Typography>
                    {this.state.commentUsers.map((user, index) => (
                        <>
                            <Grid container
                                className={this.state.commentUsers.length -1 === index ?  "pvt_container_last" : "pvt_container"}
                                key={user.id}
                                onClick={this.handleChatOpen.bind(this, user?.account?.data?.id)}>
                                <Avatar
                                    className="pvt_msg_avatar"
                                    src={user?.account?.data?.attributes?.photo}
                                    alt={user.name || 'John Doe'}
                                />
                                <Grid className="msg_info_cont">
                                    <Grid container className="name_time_cont">
                                        <Typography className="pvt_msg_name">
                                            {user?.account?.data?.attributes?.full_name}
                                        </Typography>
                                        <Typography className="pvt_msg_created_time">
                                            {moment(user.last_comment.created_at).fromNow()}
                                        </Typography>
                                    </Grid>
                                    <Typography className="pvt_msg_user_last_cmnt">
                                        {user.last_comment.comment}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {this.state?.commentUsers.length - 1 != index && <Divider style={{ width: "100%" }} />}
                        </>
                    ))}
                </Box>
            )

        } else {
            return (
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowBackIcon style={{ cursor: "pointer", display: 'flex', marginRight: '10px', color: "#000000", alignItems: 'center' }} onClick={this.handleBack} />
                        <Typography variant="h6" style={{ display: "flex", fontSize: '18px', fontWeight: 600, lineHeight: '26px', color: '#475569', fontFamily: "Nunito-Regular" }}>
                            {t('Private Message')}
                        </Typography>
                        <Typography onClick={this.handleAddChat}
                            style={{
                                padding: 0,
                                justifyContent: 'end',
                                marginLeft: 'auto',
                                cursor: 'pointer',
                                color: '#5497FF',
                                fontSize: '16px', fontWeight: 600, lineHeight: '18px', fontFamily: "Nunito-Regular"
                            }}
                        >{t('Add')}</Typography>
                    </Box>
                    {this.state.commentHistoryData.map((user, index) => (
                        <>
                            <Grid container 
                            className={this.state.commentHistoryData.length -1 === index ?  "pvt_container_last" : "pvt_container"}
                            >
                                <Avatar
                                    className="pvt_msg_avatar"
                                    src={user?.attributes?.author_image}
                                    alt={user.name || 'John Doe'}
                                />
                                <Grid className="msg_info_cont">
                                    <Grid container className="name_time_cont">
                                        <Typography className="pvt_msg_name">
                                            {user?.attributes?.author_name}
                                        </Typography>
                                        <Typography className="pvt_msg_created_time">
                                            {user.attributes?.created_at}
                                        </Typography>
                                    </Grid>
                                    <Typography className="pvt_msg_user_cmnt">
                                        {user?.attributes?.comment}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {this.state?.commentHistoryData.length - 1 != index && <Divider style={{ width: "100%" }} />}

                        </>
                    ))} 
                    <Modal open={this.state.isOpenAddChat}
                        onClose={this.handleClose} 
                        data-test-id='modal-close-click'>
                        <Box sx={style} className='pvt_msg_box'>
                            <Grid item xs={12} lg={12} sm={12} md={12}>
                                <Box className='pvt_head_box'>
                                    <Typography variant="h6" className='pvt_head_title'>
                                        {t(`Private Message`)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={12} sm={12} md={12}>
                                <Box
                                className="dialog_cont"
                            
                                >
                                    <Avatar
                                        src={this.state.userInfo.photo}
                                        style={{ height: '48px', width: '48px', marginBottom: 'auto' }}
                                    />
                                    <TextField
                                        className="pvt_chat_box"
                                        placeholder="Hey, share what's on your mind..."
                                        multiline
                                        minRows={3}
                                        maxRows={3}
                                        value={this.state.message}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            inputProps: {
                                                maxLength: 3000,
                                            }
                                        }}
                                    />
                                    </Box>
                                <Grid container className="pvt_counter_container">
                                    <Typography variant="h6" className='pvt_counter_text'>
                                        {`${3000 - this.state.message.length}`}
                                    </Typography>
                                </Grid>
                                <Grid container className="cancel_send_button_cont">
                                        <Grid item style={{ width: '48%' }}>
                                            <Button
                                                type="button"
                                                style={{
                                                    width: '100%',
                                                    fontFamily: "Nunito-Regular",
                                                    fontSize: '16px',
                                                    color: '#5497FF',
                                                    fontWeight: 800,
                                                    lineHeight: '24px',
                                                    boxShadow: 'none'
                                                }}
                                                onClick={this.handleClose}
                                                className="pvt_cancel_btn"
                                                variant="outlined"
                                                color="primary">{t('CANCEL')}
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ width: '48%' }}>
                                            <Button
                                                type="button"
                                                style={{
                                                    width: '100%',
                                                    fontFamily: "Nunito-Regular",
                                                    fontSize: '16px',
                                                    color: '#FFFFFF',
                                                    fontWeight: 800,
                                                    lineHeight: '24px',
                                                    boxShadow: 'none'
                                                }}
                                                onClick={this.handleSend.bind(this)}
                                                className={(this.state.buttonLoader || this.state.message.length === 0 || this.state.message.length === 0) ? "pvt_send_btn_disable" : "pvt_send_btn_enable"}
                                                variant="contained"
                                                disabled={this.state.buttonLoader || this.state.message.length === 0 || this.state.message.length === 0}
                                                color="primary">{t('SEND')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                            </Grid>

                        </Box>
                    </Modal>
                </Box >
            )
        }
    }

    render() {
        return (
            <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
                <Paper elevation={3} className="pvt_msg_whole_cont">
                    {this.renderChatUi()}
                </Paper>
            </TableContainer>
        );
    }
}


export default Comments
