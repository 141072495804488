import React, { useState } from "react"
import {
    Box,
    Typography,
    Badge
} from "@material-ui/core"
import { t } from "i18next"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface GroupSettingProps {
    value: string
    handleGroupSetting: any
}
const GroupSetting = (props: GroupSettingProps) => {
    const [open, setOpen] = useState(false)
    return (
        <Box>
            <Box className="grpsetting_Box" onClick={() => { setOpen(!open) }}>
                <Typography className= {props.value.length > 0 ? "grp_setting_done" : "grpsetting_text"}>
                    {props.value.length > 0 ? t(`${props.value}`) : t('Anyone can join or Private Group')}
                </Typography>
                <Badge>
                    <KeyboardArrowDownIcon className={open ? "grpsetting_up_icon" : "grpsetting_dropdown_icon"} />
                </Badge>
            </Box>
            {open && <Box className="grpSetting_Options">
                <Typography
                    style={{ marginBottom: '8px' }}
                    className="grpsetting_option_text"
                    data-test-id='first_option'
                    onClick={() => {
                        props.handleGroupSetting(`Anyone can join`)
                        setOpen(false)
                    }}
                >{t(`Anyone can join`)}</Typography>
                <Typography
                    style={{ marginTop: '8px' }}
                    className="grpsetting_option_text"
                    data-test-id='second_option'
                    onClick={() => {
                        props.handleGroupSetting(`Private Group`)
                        setOpen(false)
                    }}
                >{t(`Private Group`)}</Typography>
            </Box>}
        </Box>
    )
}

export default GroupSetting