import React, { useState } from "react";
import { Typography, Modal, Box, Grid, Button, FormControl, FormLabel, TextField } from "@material-ui/core";
import { t } from 'i18next';
import LanguageDropDown from "./LanguageDropDown";
import { styled } from '@material-ui/styles';

export const CustomTextField = styled(TextField)(() => ({
    '& .MuiOutlinedInput-inputMultiline': {
            scrollbarWidth: 'none'
    },
    
}));
export const LikeShareButton = ({ onSend, onrhLanguageChange, onrhDescriptionChange, state, authorId, data, isLike, onClick, commentOnClick, shareHandler }: { onSend?: any, onrhLanguageChange?: any, onrhDescriptionChange?: any, state?: any, authorId?: string, data?: any, isLike: boolean, onClick: any, commentOnClick: any, shareHandler?: any }) => {
    const [open, setOpen] = useState(false)
    const [click, setClick] = useState(false)

    const [counter, setCounter] = useState('')
    const maxCharacters = 1024;

    const handleChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length <= maxCharacters) {
            setCounter(inputValue);
            onrhDescriptionChange(event)
        }
    };
    let url = window.location.origin + `${'/CommunityForumWeb/' + data?.id}`

    const checkArray = (data: any[])=>{
        if(Array.isArray(data) && data.length === 0){
            return true
        }
        return false
    }

    const disableSRButton = checkArray(state.postDetails.attributes.images) &&
        checkArray(state.postDetails.attributes.videos) &&
        checkArray(state.postDetails.attributes.audios) &&
        checkArray(state.postDetails.attributes.documents)
  return (
        <>
            <div className="likesCommentsButtons">
                <div className="likesContainer">
                    <div className="likes" test-id="likeButton" onClick={onClick}>
                        {isLike ?
                            <img src={require("./liked.png")} />
                            : <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M7.50016 17.5H15.0002C15.6918 17.5 16.2835 17.0834 16.5335 16.4834L19.0502 10.6084C19.1252 10.4167 19.1668 10.2167 19.1668 10V8.33337C19.1668 7.41671 18.4168 6.66671 17.5002 6.66671H12.2418L13.0335 2.85837L13.0585 2.59171C13.0585 2.25004 12.9168 1.93337 12.6918 1.70837L11.8085 0.833374L6.31683 6.32504C6.01683 6.62504 5.8335 7.04171 5.8335 7.50004V15.8334C5.8335 16.75 6.5835 17.5 7.50016 17.5ZM7.50016 7.50004L11.1168 3.88337L10.0002 8.33337H17.5002V10L15.0002 15.8334H7.50016V7.50004ZM0.833496 7.50004H4.16683V17.5H0.833496V7.50004Z"
                                    fill="#64748B" />
                            </svg>
                        }
                        <Typography className="likesText">{isLike ? t('Liked') : t('Like')}</Typography>
                    </div>
                    <div className="bar"></div>
                    <div className="likes" onClick={commentOnClick}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M3.33317 3.33329H16.6665V13.3333H4.30817L3.33317 14.3083V3.33329ZM3.33317 1.66663C2.4165 1.66663 1.67484 2.41663 1.67484 3.33329L1.6665 18.3333L4.99984 15H16.6665C17.5832 15 18.3332 14.25 18.3332 13.3333V3.33329C18.3332 2.41663 17.5832 1.66663 16.6665 1.66663H3.33317ZM4.99984 9.99996H11.6665V11.6666H4.99984V9.99996ZM4.99984 7.49996H14.9998V9.16662H4.99984V7.49996ZM4.99984 4.99996H14.9998V6.66663H4.99984V4.99996Z"
                                fill="#64748B" />
                        </svg>
                        <Typography className="likesText">
                            {t('Comment')}
                        </Typography>
                    </div>
                </div>
                <div
                    onClick={() => { 
                        if(onSend !== undefined){
                            setOpen(true) 
                        }
                        else{
                            shareHandler()
                        }
                    }}
                    className="likes">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M15 13.4C14.3667 13.4 13.8 13.65 13.3667 14.0416L7.425 10.5833C7.46667 10.3916 7.5 10.2 7.5 9.99996C7.5 9.79996 7.46667 9.60829 7.425 9.41663L13.3 5.99163C13.75 6.40829 14.3417 6.66663 15 6.66663C16.3833 6.66663 17.5 5.54996 17.5 4.16663C17.5 2.78329 16.3833 1.66663 15 1.66663C13.6167 1.66663 12.5 2.78329 12.5 4.16663C12.5 4.36663 12.5333 4.55829 12.575 4.74996L6.7 8.17496C6.25 7.75829 5.65833 7.49996 5 7.49996C3.61667 7.49996 2.5 8.61663 2.5 9.99996C2.5 11.3833 3.61667 12.5 5 12.5C5.65833 12.5 6.25 12.2416 6.7 11.825L12.6333 15.2916C12.5917 15.4666 12.5667 15.65 12.5667 15.8333C12.5667 17.175 13.6583 18.2666 15 18.2666C16.3417 18.2666 17.4333 17.175 17.4333 15.8333C17.4333 14.4916 16.3417 13.4 15 13.4ZM15 3.33329C15.4583 3.33329 15.8333 3.70829 15.8333 4.16663C15.8333 4.62496 15.4583 4.99996 15 4.99996C14.5417 4.99996 14.1667 4.62496 14.1667 4.16663C14.1667 3.70829 14.5417 3.33329 15 3.33329ZM5 10.8333C4.54167 10.8333 4.16667 10.4583 4.16667 9.99996C4.16667 9.54163 4.54167 9.16663 5 9.16663C5.45833 9.16663 5.83333 9.54163 5.83333 9.99996C5.83333 10.4583 5.45833 10.8333 5 10.8333ZM15 16.6833C14.5417 16.6833 14.1667 16.3083 14.1667 15.85C14.1667 15.3916 14.5417 15.0166 15 15.0166C15.4583 15.0166 15.8333 15.3916 15.8333 15.85C15.8333 16.3083 15.4583 16.6833 15 16.6833Z"
                            fill="#64748B" />
                    </svg>
                    <Typography className="likesText">{t('Share')}</Typography>
                </div>
            </div>
            <Modal open={open} onClose={() => {
                setOpen(false)
                onrhDescriptionChange({ target: { value: '' } })
                onrhLanguageChange('', { title: '' })
                setClick(false)
            }} data-test-id='resource_hub_test'>
                <Box className='share_modal_box'>
                    <Grid item xs={12} lg={12} sm={12} md={12}>
                        <Grid className='share_box'>
                            <Typography variant="h6" className='share_text'>
                                {t(`Share`)}
                            </Typography>
                            <div onClick={() => {
                                setOpen(false)
                                setClick(false)
                                onrhDescriptionChange({ target: { value: '' } })
                                onrhLanguageChange('', { title: '' })
                            }} style={{ cursor: 'pointer' }}>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.2005 4.30713C12.0759 4.18229 11.9068 4.11214 11.7305 4.11214C11.5541 4.11214 11.385 4.18229 11.2605 4.30713L8.00047 7.56046L4.74047 4.30046C4.61591 4.17562 4.44681 4.10547 4.27047 4.10547C4.09412 4.10547 3.92502 4.17562 3.80047 4.30046C3.54047 4.56046 3.54047 4.98046 3.80047 5.24046L7.06047 8.50046L3.80047 11.7605C3.54047 12.0205 3.54047 12.4405 3.80047 12.7005C4.06047 12.9605 4.48047 12.9605 4.74047 12.7005L8.00047 9.44046L11.2605 12.7005C11.5205 12.9605 11.9405 12.9605 12.2005 12.7005C12.4605 12.4405 12.4605 12.0205 12.2005 11.7605L8.94047 8.50046L12.2005 5.24046C12.4538 4.98713 12.4538 4.56046 12.2005 4.30713Z" fill="white" />
                                </svg>
                            </div>
                        </Grid>
                        <Grid container className="share_info_container">
                            <Grid container className="copy_url_container" >
                                <Grid className="link_container" xs={7} lg={9} sm={8} md={9}>
                                    <Typography variant="h6" className='link_text'>
                                        {url}
                                    </Typography>
                                </Grid>
                                <Grid className="copy_container" xs={5} lg={3} sm={4} md={3} onClick={shareHandler}>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 13.8993C14.3667 13.8993 13.8 14.1494 13.3667 14.541L7.425 11.0827C7.46667 10.891 7.5 10.6993 7.5 10.4993C7.5 10.2993 7.46667 10.1077 7.425 9.91602L13.3 6.49102C13.75 6.90768 14.3417 7.16602 15 7.16602C16.3833 7.16602 17.5 6.04935 17.5 4.66602C17.5 3.28268 16.3833 2.16602 15 2.16602C13.6167 2.16602 12.5 3.28268 12.5 4.66602C12.5 4.86602 12.5333 5.05768 12.575 5.24935L6.7 8.67435C6.25 8.25768 5.65833 7.99935 5 7.99935C3.61667 7.99935 2.5 9.11602 2.5 10.4993C2.5 11.8827 3.61667 12.9993 5 12.9993C5.65833 12.9993 6.25 12.741 6.7 12.3243L12.6333 15.791C12.5917 15.966 12.5667 16.1493 12.5667 16.3327C12.5667 17.6743 13.6583 18.766 15 18.766C16.3417 18.766 17.4333 17.6743 17.4333 16.3327C17.4333 14.991 16.3417 13.8993 15 13.8993ZM15 3.83268C15.4583 3.83268 15.8333 4.20768 15.8333 4.66602C15.8333 5.12435 15.4583 5.49935 15 5.49935C14.5417 5.49935 14.1667 5.12435 14.1667 4.66602C14.1667 4.20768 14.5417 3.83268 15 3.83268ZM5 11.3327C4.54167 11.3327 4.16667 10.9577 4.16667 10.4993C4.16667 10.041 4.54167 9.66602 5 9.66602C5.45833 9.66602 5.83333 10.041 5.83333 10.4993C5.83333 10.9577 5.45833 11.3327 5 11.3327ZM15 17.1827C14.5417 17.1827 14.1667 16.8077 14.1667 16.3493C14.1667 15.891 14.5417 15.516 15 15.516C15.4583 15.516 15.8333 15.891 15.8333 16.3493C15.8333 16.8077 15.4583 17.1827 15 17.1827Z" fill="#5497FF" />
                                    </svg>
                                    <Typography variant="h6" className='copy_text'
                                    >
                                        {t('Copy URL')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {state !== undefined && authorId == data?.attributes.author_id && (<><Grid container className='or_container'>
                                <Typography variant="h6" className='or_text'>
                                    {t('OR')}
                                </Typography>
                            </Grid>
                                <Grid className="share_tp_resource_container">
                                    <Button
                                        type="button"
                                        style={{
                                            width: '100%',
                                            fontFamily: "Nunito-Regular",
                                            fontSize: '16px',
                                            color: '#FFFFFF',
                                            fontWeight: 800,
                                            lineHeight: '24px',
                                            boxShadow: 'none',
                                            opacity: disableSRButton ? 0.5 : 1
                                        }}
                                        data-test-id='add-button'
                                        onClick={() => {
                                            setClick(true)
                                        }}
                                        className="share_to_resource_hub_btn"
                                        variant="contained"
                                        disabled={disableSRButton}
                                        color="primary">{t('SHARE TO RESOURCE HUB')}
                                    </Button>
                                  {disableSRButton && <Typography data-test-id={`error`} className="validation_check_msg">{t('No media/attachments are available to share')}</Typography>}
                                </Grid>

                                {click && <Grid className="resource_hub_info_container">
                                    <LanguageDropDown
                                        title={'Select Language'}
                                        placeholderText={t("Language")}
                                        initialValue={{ title: state?.rh_language }}
                                        options={[{ title: 'English' }, { title: 'French' }, { title: 'Bengali' }]}
                                        onChange={onrhLanguageChange}
                                    />
                                    <Grid item xs={12} lg={12} sm={12} md={12} className="description_section">
                                        <FormControl fullWidth>
                                            <FormLabel className="description_title_text">
                                                {t('Description')}
                                            </FormLabel>
                                            <CustomTextField
                                                multiline
                                                minRows={4}
                                                maxRows={4}
                                                value={state?.rh_description}
                                                onChange={(value: any) => { 
                                                    handleChange(value)
                                                 }}
                                                type={'text'}
                                                placeholder={t("Write description here")}
                                                className="description_box"
                                                variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                        lineHeight: '22px',
                                                        color: '#1E293B',
                                                        scrollbarWidth: 'none'
                                                    },
                                                }}
                                            />
                                            <Grid container className="counter_container">
                                                <Typography variant="h6" className='counter_text'>
                                                {`${maxCharacters - counter.length}`}
                                                </Typography>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid container className="cancel_send_button_container">
                                        <Grid item style={{ width: '48%' }}>
                                            <Button
                                                type="button"
                                                style={{
                                                    width: '100%',
                                                    fontFamily: "Nunito-Regular",
                                                    fontSize: '16px',
                                                    color: '#5497FF',
                                                    fontWeight: 800,
                                                    lineHeight: '24px',
                                                    boxShadow: 'none'
                                                }}
                                                data-test-id='add-button'
                                                onClick={() => {
                                                    setClick(false)
                                                    onrhDescriptionChange({ target: { value: '' } })
                                                    onrhLanguageChange('', { title: '' })
                                                }}
                                                className="cancel_btn"
                                                variant="outlined"
                                                color="primary">{t('CANCEL')}
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ width: '48%' }}>
                                            <Button
                                                type="button"
                                                style={{
                                                    width: '100%',
                                                    fontFamily: "Nunito-Regular",
                                                    fontSize: '16px',
                                                    color: '#FFFFFF',
                                                    fontWeight: 800,
                                                    lineHeight: '24px',
                                                    boxShadow: 'none'
                                                }}
                                                data-test-id='add-button'
                                                onClick={() => {
                                                    console.log('api called0', data.id)
                                                    onSend(data.id)
                                                    setOpen(false)
                                                    setClick(false)
                                                }}
                                                className={(state?.rh_description.length === 0 || state?.rh_language.length === 0) ? "rh_send_btn_disable" : "rh_send_btn_enable"}
                                                variant="contained"
                                                disabled={state?.rh_description.length === 0 || state?.rh_language.length === 0}
                                                color="primary">{t('SEND')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            </>)}
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}