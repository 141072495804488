export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backgroundImage = require("../assets/backgroundImage.png");
export const Rectangle5 = require("../assets/Rectangle5.png");
export const Rectangle7 = require("../assets/Rectangle7.png");
export const Group16 = require("../assets/Group16.png");
export const camera = require("../assets/camera.png");
export const gallery = require("../assets/gallery.png");
export const user1 = require("../assets/user1.png");
export const user2 = require("../assets/user2.png");
export const user3 = require("../assets/user3.png");
export const user4 = require("../assets/user4.png");
export const download = require("../assets/download.png");
export const tickmark = require("../assets/tickmark.png");
export const ticktrue = require("../assets/ticktrue.png");
export const user5 = require("../assets/image_ (2).png")
export const deleteGrpIcon = require("../assets/delete-group.png");
export const leaveGrpIcon = require("../assets/exit-group.png");