import React from "react";
import {
    Paper,
    TextareaAutosize,
    TextField,
    FormControl,
    Select,
    MenuItem,
    FormLabel,
} from "@material-ui/core";
import Buttons from "./buttons.web";
import { InterestCard } from "./interestCard.web";
import { t } from "i18next";
import GroupSetting from "./GroupSetting.web";
import { styled } from '@material-ui/styles';

const CustomTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-inputMultiline': {
          scrollbarWidth: 'none'
  },
  
}));
export const RestCreateNewGroupForm = ({
      groupName, groupNameError, description, groupTypeError, value, interests, interestIds,
      showOtherImpactFields, showOtherSkillsFields, otherInterestImpactFields, impactInterestUniqueError, otherInterestSkillsFields,
      skillsInterestUniqueError, userInterestError, handleSetState, handleSelectInterest, handleShowFields, handleFormFields, handleOtherFields,
      handleOpen, selected_interest, uploadCoverPhoto, uploadProfilePhoto, handleOpenGroupType, handleCloseGroupType, openOtherComponent, iconPhotoKey,
      coverPhotoKey, removedInterestIds, selectedInterestIds, handleGroupSetting
}:any) => {
    const data = {
      groupName, description, value, uploadCoverPhoto, uploadProfilePhoto, otherInterestImpactFields, interests, otherInterestSkillsFields,
      selected_interest, showOtherImpactFields, showOtherSkillsFields, interestIds, iconPhotoKey, coverPhotoKey, removedInterestIds, selectedInterestIds}
    return(
      <Paper elevation={0} style={{ padding: '20px'}} className="profile-desc"> 
      <div className="profile-form-sec" style={{ display: 'flex' }}>
      <div className="form-group-sec" style={{ marginRight: '20px', flex: '1' }}>
                <FormControl fullWidth>
                    <FormLabel className="grp_titles">
                        {t("Group Name")}
                    </FormLabel>
                    <TextField
                        value={groupName}
                        type={'text'}
                        placeholder={t("Enter Group Name")}
                        variant="outlined"
                        name='group_name'
                        className="grp_textBox"
                        onChange={(event) => handleSetState(event, "groupName", "text")}
                        InputProps={{
                          style: {
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '24px',
                              color: '#1E293B',
                              fontFamily: "Nunito-Regular"
                          },
                          inputProps:{
                              maxLength: 42
                          }
                      }}
                    />
                </FormControl>
                {!groupName &&
                  <p className="error-msg">{groupNameError}</p>
                }
            <FormControl fullWidth style={{ marginTop: '20px' }}>
              <FormLabel className="grp_titles">
                {t("Description")}
              </FormLabel>
              <CustomTextField
                multiline
                minRows={4}
                maxRows={4}
                value={description}
                onChange={(event) => handleSetState(event, "description", "text")}
                type={'text'}
                placeholder={t("Write group description here")}
                className="about-box_here"
                variant="outlined"
                name={'description'}
                InputProps={{
                  style: {
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    color: '#1E293B',
                    fontFamily: "Nunito-Regular",
                    scrollbarWidth: 'none'
                  },
                  inputProps: {
                    maxLength: 2048
                  }
                }}
              />
            </FormControl>
                  <FormControl variant="outlined" fullWidth style={{marginTop:'20px'}}>
                <FormLabel className="grp_titles">
                    {t("Group Settings")}
                </FormLabel>
                <GroupSetting value={value} handleGroupSetting= {handleGroupSetting}/>
                </FormControl>
            {!value &&
              <p className="error-msg">{groupTypeError}</p>
             }
        </div> 
        <InterestCard interests={interests} interestIds={interestIds} handleSelectInterest={handleSelectInterest} showOtherImpactFields={showOtherImpactFields}
         showOtherSkillsFields={showOtherSkillsFields} handleShowFields={handleShowFields}
         otherInterestImpactFields={otherInterestImpactFields} handleFormFields={handleFormFields} handleOtherFields={handleOtherFields}
         impactInterestUniqueError={impactInterestUniqueError} otherInterestSkillsFields={otherInterestSkillsFields}
         skillsInterestUniqueError={skillsInterestUniqueError} userInterestError={userInterestError} />       
      </div>
       <Buttons navigation={""} id={""} handleOpen={handleOpen} data={data} openOtherComponent={openOtherComponent} />
     </Paper> 
    ) 
}

const styles={
    descriptionBox:{
      height:'120px',
      display: 'flex',
      padding: '10px 16px',
      gap: '8px',
      background: '#FFFFFF',
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      width:'100%'
    }
  }