import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    // Customizable Area Start
    Grid,
    Paper,
    Badge,
    Avatar,
    Divider,
    // Customizable Area End
    IconButton,
    Modal,
    TextField,
    styled,
} from "@material-ui/core";

// Customizable Area Start
import "../assets/membersdirectory.scss"
import Header from "../../../components/src/Header.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { user4 } from "./assets";
import { t } from "i18next";
import Dropdown from "./components/Dropdown";
import MemberInfo from "../src/components/MemberInfo";
import { getStorageData } from "../../../framework/src/Utilities";
import '../../../blocks/comments/assets/Comments.scss';
// comments/src/assets/Comments.scss';
// Customizable Area End

import MemberDetailsController, {
    Props,
    configJSON,
} from "./MemberDetailsController.web";
import UserAboutMe from "../../user-profile-basic/src/components/UserAboutMe.web";

export default class MemberDetails extends MemberDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    isViewMore = () => {
        return this.state.visibleComments < this.state.commentData.length
    }

    isViewLess = () => {
        return this.state.visibleComments > 2  
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div>
                <Header type={"MemberDetails"} navigation={this.props.navigation} />
                <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }}>
                    <Grid container className="member_directory_page">
                        <Grid container spacing={4} style={{ maxWidth: '100%', width: '100%' }}>
                            <Grid item xs={12} md={8} lg={8} xl={8} sm={12}>
                                <Paper elevation={3} className="members_directory_list" >
                                    <Box>
                                        <div onClick={this.goBackToMemberDirectory} className="member_details_container">
                                            <Badge>
                                                <ArrowBackIcon
                                                    className="arrow_button"
                                                ></ArrowBackIcon>
                                            </Badge>
                                            <Typography
                                                variant="subtitle2"
                                                className="member_details_text"
                                            >{t("Member Details")}</Typography>
                                        </div>
                                        <MemberInfo user={this.state.data} />
                                    </Box>
                                </Paper>

                            </Grid>
                            <Grid item xs={12} md={4} lg={4} xl={4} sm={12}>
                                <Paper elevation={3} style={{borderRadius:'8px'}}>
                                <Box style={{ display: 'flex', flexDirection: 'column', padding:'20px' }}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h6" style={{ display: "flex", fontSize: '18px', fontWeight: 600, lineHeight: '26px', color: '#475569', fontFamily: "Nunito-Regular" }}>
                                            {t('Private Message')}
                                        </Typography>
                                        <Typography data-test-id="add" onClick={this.handleStartChat}
                                            style={{
                                                padding: 0,
                                                justifyContent: 'end',
                                                marginLeft: 'auto',
                                                cursor: 'pointer',
                                                color: '#5497FF',
                                                fontSize: '16px', fontWeight: 600, lineHeight: '18px', fontFamily: "Nunito-Regular"
                                            }}
                                        >{t('Add')}</Typography>
                                    </Box>
                                    {this.state.commentData.length > 0 ? 
                                        this.state.commentData.slice(0, this.state.visibleComments).map((user, index) => (
                                            <>
                                                <Grid container 
                                                style={{flexWrap: 'nowrap'}}
                                                className={this.state.commentData.length -1 === index ?  "pvt_container_last" : "pvt_container"}
                                                >
                                                    <Avatar
                                                        className="pvt_msg_avatar"
                                                        src={user?.attributes?.author_image}
                                                        alt={user.name || 'John Doe'}
                                                    />
                                                    <Grid className="msg_info_cont">
                                                        <Grid container className="name_time_cont">
                                                            <Typography className="userName">
                                                                {user?.attributes?.author_name}
                                                            </Typography>
                                                            <Typography className="pvt_msg_created_time">
                                                                {user.attributes?.created_at}
                                                            </Typography>
                                                        </Grid>
                                                        <Typography className="pvt_msg_user_cmnt">
                                                            {user?.attributes?.comment}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                {this.state?.commentData.length - 1 != index && <Divider style={{ width: "100%" }} />}

                                            </>
                                    )):
                                        <Box><NoCommentsText>No comments</NoCommentsText></Box>
                                    }
                                    <Box style={{
                                        textAlign:'center',
                                        marginTop:'20px'}}
                                    >
                                        {this.state.visibleComments < this.state.commentData.length &&
                                            <Button variant="text" 
                                                data-test-id="viewMore"
                                                onClick={this.handleViewMore}
                                                style={webStyle.viewMore}>{t('View More')}
                                            </Button>
                                        }
                                        {!this.isViewMore() && this.state.visibleComments > this.state.commentsPerPage && 
                                            <Button variant="text" 
                                                data-test-id="viewLess"
                                                onClick={this.handleViewLess}
                                                style={webStyle.viewMore}>{t('View Less')}
                                            </Button>
                                        }  
                                    </Box>
                                    
                                    <Modal 
                                        open={this.state.isOpenStartChat}
                                        onClose={this.handleCloseChat} 
                                        data-test-id='modal-close-click'>
                                        <Box sx={style} className='pvt_msg_box'>
                                            <Grid item xs={12} lg={12} sm={12} md={12}>
                                                <Box className='pvt_head_box'>
                                                    <Typography variant="h6" className='pvt_head_title'>
                                                        {t(`Private Message`)}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} lg={12} sm={12} md={12}>
                                                <Box
                                                className="dialog_cont">
                                                    <Avatar
                                                        src={this.state.userData.photo}
                                                        style={{ height: '48px', width: '48px', marginBottom: 'auto' }}
                                                    />
                                                    <TextField
                                                        className="pvt_chat_box"
                                                        placeholder="Hey, share what's on your mind..."
                                                        multiline
                                                        data-test-id="comment"
                                                        minRows={3}
                                                        maxRows={3}
                                                        value={this.state.message}
                                                        onChange={this.handleInputChange}
                                                        InputProps={{
                                                            inputProps: {
                                                                maxLength: 3000,
                                                            }
                                                        }}
                                                    />
                                                    </Box>
                                                    <Grid container className="pvt_counter_container">
                                                        <Typography variant="h6" className='pvt_counter_text'>
                                                            {`${3000 - this.state.message.length}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container className="cancel_send_button_cont">
                                                        <Grid item style={{ width: '48%' }}>
                                                            <Button
                                                                type="button"
                                                                style={{
                                                                    width: '100%',
                                                                    fontFamily: "Nunito-Regular",
                                                                    fontSize: '16px',
                                                                    color: '#5497FF',
                                                                    fontWeight: 800,
                                                                    lineHeight: '24px',
                                                                    boxShadow: 'none'
                                                                }}
                                                                onClick={this.handleCloseChat}
                                                                data-test-id="cancelBtn"
                                                                className="pvt_cancel_btn"
                                                                variant="outlined"
                                                                color="primary">{t('CANCEL')}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item style={{ width: '48%' }}>
                                                            <Button
                                                                data-test-id="sendBtn"
                                                                type="button"
                                                                style={{
                                                                    width: '100%',
                                                                    fontFamily: "Nunito-Regular",
                                                                    fontSize: '16px',
                                                                    color: '#FFFFFF',
                                                                    fontWeight: 800,
                                                                    lineHeight: '24px',
                                                                    boxShadow: 'none'
                                                                }}
                                                                onClick={this.handleCreateComment.bind(this)}
                                                                className={(this.state.buttonClicked || this.state.message.length === 0 || this.state.message.length === 0) ? "pvt_send_btn_disable" : "pvt_send_btn_enable"}
                                                                variant="contained"
                                                                disabled={this.state.buttonClicked || this.state.message.length === 0 || this.state.message.length === 0}
                                                                color="primary">{t('SEND')}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                            </Grid>

                                        </Box>
                                    </Modal>
                                </Box >
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            // <></>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 588,
    borderRadius: '8px',
    outline: 'none',
};

const NoCommentsText = styled(Typography)({
    fontFamily: "Nunito-Regular",
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '18px',
    color: 'rgba(100, 116, 139, 1)',
    textAlign:'center',
    marginTop:'20px'
})

const webStyle = {
    viewMore : {
        fontFamily: "Nunito-Regular",
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '18px',
        color: 'rgba(84, 151, 255, 1)',
    } 
};
// Customizable Area End
