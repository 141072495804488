import React from "react";
import PaginationBlock from "../../../../components/src/Pagination.web";
import { Box } from '@material-ui/core';

interface ExploreGroupPaginationInterface {
    current_page: number
    total_pages: number
}
interface ExploreGroupPaginationProps {
    groupList: any[]
    exploreGroupPagination: ExploreGroupPaginationInterface
    handleExploreGroupsPagination: Function
    valueTab: number
    activeIndex: number
}

const ExploreGroupsPagination = (props: ExploreGroupPaginationProps) => {
    return (
        props.groupList.length > 0 && props.valueTab == props.activeIndex ? <Box style={{marginTop: '-16px'}}>
            <PaginationBlock
                data-test-id="groups-pagination"
                page={props.exploreGroupPagination.current_page}
                totalCount={props.exploreGroupPagination.total_pages}
                handlePagination={props.handleExploreGroupsPagination}
            />
        </Box> : <></>
    )
}

export default ExploreGroupsPagination