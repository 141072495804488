import React from "react";
import {
    Box,
} from "@material-ui/core";
import '../../assets/Community.scss';
import EditPost from "./EditPost.web";
import UserPostDetail from "./UserPostDetail.web";

const ModalContainer = ({ state, onFilteredHandler, onExistedMediaHandler}: any) => {
    
    return (
        <Box>
            {state.isEdit ?
            <EditPost postMedia={state.postMedia} existedMedia={state.existedMedia} onFilteredHandler={onFilteredHandler} onExistedMediaHandler={onExistedMediaHandler} />
            :
            <UserPostDetail postMedia={state.postMedia} onFilteredHandler={onFilteredHandler} loading={state.loading} />
            }
        </Box>
    );
};

export default ModalContainer;
