import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

export interface ICommentData {
  name: string,
  attributes: {
    author_image: string,
    author_name: string,
    created_at: string,
    comment: string
  }
}

interface S {
    data: {
      attributes: {
        photo: string,
        full_name: string,
        about_yourself: string,
        email: string,
        number_visible: boolean,
        full_phone_number: string,
        sttae: string,
        city: string,
        time_zone: string,
        working_language: string,
        preferred_language : string,
        connections: {
          name: string
        }[],
        skill_name: {
          name: string
        }[],
        interest_name: {
          name: string
        }[],
        organizational_affilation: string,
        experience: string,
        groups: {
          logo: string,
          title: string
        }[]
      }
    },
    commentData: ICommentData[],
    isOpenStartChat: boolean,
    message: string,
    userData:{
      photo: string
    },
    memberId: string,
    buttonClicked: boolean,
    visibleComments: number,
    commentsPerPage: number
}

interface SS {}

export default class MemberDetailsController extends BlockComponent<Props, S, SS> {
    getMemberDetails:string="";
    commentApiCallId: string = "";
    postCommentApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
        ];
    
        this.state = {
            data: {
                attributes: {
                  photo: '',
                  full_name: '',
                  about_yourself: '',
                  email: '',
                  number_visible: false,
                  full_phone_number: '',
                  sttae: '',
                  city: '',
                  time_zone: '',
                  working_language: '',
                  preferred_language : '',
                  connections: [],
                  skill_name: [],
                  interest_name: [],
                  organizational_affilation: '',
                  experience: '',
                  groups: []
                }
            },
            commentData: [],
            isOpenStartChat: false,
            message: '',
            userData: {
              photo: ''
            },
            memberId: '',
            buttonClicked: false,
            visibleComments: 10,
            commentsPerPage: 10
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      }
      async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
    
          if (apiRequestCallId != null) {
             if(apiRequestCallId === this.getMemberDetails){
              this.setState({
                data: responseJson?.data
              }); 
            }

            if(apiRequestCallId === this.commentApiCallId) {
              this.setState({commentData: responseJson?.data})
            }

            if(apiRequestCallId === this.postCommentApiCallId) {
              this.setState({ buttonClicked: false, message:'' })
              this.getCommentData(this.state.memberId)
            }
      }
    }
        // Customizable Area End
      }
      async componentDidMount() {
        const memberData = await getStorageData("dataId")
        const userData = JSON.parse(localStorage.getItem("userInfo")!)
        this.setState({userData: userData, memberId: memberData})
        this.getMemberDetailsApiCall(memberData)
        this.getCommentData(memberData)
      }
      goBackToMemberDirectory=()=>{
        this.props.navigation.goBack()
      }
      getMemberDetailsApiCall = (memberId: string) => {
        const header = {
          "Content-Type": "application/json",
          "token": String(localStorage.getItem("authToken")),
      };
      
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.getMemberDetails = requestMessage.messageId;
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account/find_user/${memberId}`
      );
      
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
      
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      getCommentData = (memberId: string) => {
        const header = {
          "Content-Type": "application/json",
          "token": String(localStorage.getItem("authToken")),
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.commentApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `show_single_message?account_id=${memberId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
        return true;
      }
    
      handleStartChat = () => {
        this.setState({ isOpenStartChat: true })
      }
    
      handleCloseChat = () => {
        this.setState({ isOpenStartChat: false, message: '' })
      }

      handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ message: event.target.value.trimStart() });
      }

      handleCreateComment = () => {
        this.setState({ isOpenStartChat: false, buttonClicked: true })
        this.postComment()
      }

      postComment = () => {
        const header = {
          token: localStorage.getItem("authToken"),
        };
    
        const formdata = new FormData()
    
        formdata.append("[data][attributes][comment]", this.state.message)
        formdata.append("[data][attributes][commentable_id]", this.state.memberId)
        formdata.append("[data][attributes][commentable_type]", 'AccountBlock::Account')
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.postCommentApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createCommentEndpoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationPostApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      handleViewMore = () => {
        this.setState((prev) => ({
          visibleComments: Math.min(
            prev.visibleComments + this.state.commentsPerPage,
            prev.commentData.length
          )
        }))
      }

      handleViewLess = () => {
        this.setState({ visibleComments: 10 })
        window.scrollTo(0,0)
      }
}